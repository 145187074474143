<template>
  <component :is="cohortData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="cohortData === undefined">
      <h4 class="alert-heading">Error fetching cohort data</h4>
      <div class="alert-body">
        No cohort found with this cohort id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-cohort-list' }"> Cohort List </b-link>
        for other cohorts.
      </div>
    </b-alert>

    <b-tabs v-if="cohortData" pills>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <cohort-edit-tab-information :cohort-data.sync="cohortData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Sessions -->
      <b-tab disabled="">
        <template #title>
          <feather-icon icon="BookIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Sessions</span>
        </template>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import receivableStoreModule from '../receivableStoreModule';
import CohortEditTabInformation from './CohortEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CohortEditTabInformation,
  },
  setup() {
    const cohortData = ref(null);

    const COHORT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(COHORT_APP_STORE_MODULE_NAME))
      store.registerModule(COHORT_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COHORT_APP_STORE_MODULE_NAME)) store.unregisterModule(COHORT_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-accounting-receivable/fetchCohort', { id: router.currentRoute.params.id })
      .then((response) => {
        let { data } = response;
        if (!data.program) data.program = {};
        cohortData.value = data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          cohortData.value = undefined;
        }
      });

    return {
      cohortData,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
