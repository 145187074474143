<template>
  <div>
    <!-- Header: Personal Info -->

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->

      <div class="d-flex">
        <h4 class="mb-0">Cohort Information</h4>
      </div>
      <b-row class="mt-1">
        <!-- Field: Cohort Name -->
        <b-col cols="12" lg="8" md="6" xs="12">
          <b-form-group label="Name" label-for="cohort-name">
            <b-form-input id="cohort-name" required v-model="cohortDataInfo.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Status" label-for="cohort-status">
            <v-select
              v-model="cohortDataInfo.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              required
              input-id="cohort-status"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex">
        <h4 class="mb-0 mt-3">
          Program Information <span class="font-small-3 text-muted">(optional: used for scheduling)</span>
        </h4>
      </div>
      <b-row class="mt-1">
        <!-- Col: Pathway Name -->
        <b-col cols="12" xl="4" sm="12" class="mb-lg-1">
          <label>Course Name</label>
          <b-form-input
            id="account-data-sales-person"
            v-model="cohortDataInfo.program.name"
            placeholder="CompTIA Security+"
          />
        </b-col>

        <!-- Col: Program Start Date -->
        <b-col cols="12" xl="4" sm="12" class="mb-lg-1">
          <label>Program Start</label>
          <b-form-datepicker
            class="w-100"
            :id="`account-data-program-start`"
            :value="cohortDataInfo.program.startDate"
            @input="(val) => (cohortDataInfo.program.startDate = val)"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
            placeholder="n/a"
          />
        </b-col>

        <!-- Col: Last Date of Attendance -->
        <b-col cols="12" xl="4" sm="12" class="mb-lg-1">
          <label>Program End</label>
          <b-form-datepicker
            class="w-100"
            :id="`account-data-program-end`"
            :value="cohortDataInfo.program.endDate"
            @input="(val) => (cohortDataInfo.program.endDate = val)"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
            placeholder="n/a"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormDatepicker } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useCohortsList from '../cohort-list/useCohortsList';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormDatepicker,
    ToastificationContent,
  },
  props: {
    cohortData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    doSaveChanges() {
      const pwd = this.cohortDataInfo;

      const pl = {
        name: pwd.name,
        status: pwd.status,
        program: pwd.program,
      };

      store
        .dispatch('app-accounting-receivable/updateCohort', { id: pwd.id, cohortData: pl })
        .then((response) => {
          this.$emit('update:cohort-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cohort Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit cohort',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  setup(props) {
    const cohortDataInfo = ref(props.cohortData);

    const COHORT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(COHORT_APP_STORE_MODULE_NAME))
      store.registerModule(COHORT_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COHORT_APP_STORE_MODULE_NAME)) store.unregisterModule(COHORT_APP_STORE_MODULE_NAME);
    });

    const { statusOptions, visibilityOptions } = useCohortsList();

    return {
      cohortDataInfo,
      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
